import requestUtil from "../config/requestUtil"
import { getParam } from './utils'
import wx from "weixin-js-sdk"
import { Toast } from 'vant';
import store from '@/store';
import router from '@/router';
/**
 * 获取app类型
 */
export function appType() {
    var u = navigator.userAgent;
    var ua = u.toLowerCase()
    var isMini = u.indexOf('miniProgram') > -1
    var isWx = ua.match(/MicroMessenger/i) == 'micromessenger'
    var isAndroid = u.indexOf('Android') > -1 || u.indexOf('Adr') > -1; //android终端
    var isiOS = u.indexOf('AppleWebKit') > -1
    var isTTWebView = navigator.userAgent.toLowerCase().includes("toutiaomicroapp");
    if (isMini) return 'mini'
    if (isTTWebView) return "toutiao"
    if (isWx) return 'wx'
    if (isAndroid) return 'and'
    if (isiOS) return 'ios'
}
/**
 * 是否是移动端
 */
export function isMobile() {
    let flag = navigator.userAgent.match(
        /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
    );
    return flag ? true : false
}

/**
 * 作用：获取城市信息
 */
export async function appGetCityId() {
    if (appType() == "and") {
        return (App.getCityId())
    }
    if (appType() == "ios") {
        if (window.webkit.messageHandlers.getCityId) {
            window.webkit.messageHandlers.getCityId.postMessage("11")
            return await getAppMethodListener('getCityId')
        } else {
            console.log('getcityid-ipad');
            window.webkit.messageHandlers.getTeacherCityId.postMessage("11")
            return await getAppMethodListener('getTeacherCityId')
        }

    }
    if (appType() === 'mini') {
        return getParam().cityId
    }
}

/**
 * 作用：获取当前用户信息
 */
export async function appGetUser() {
    if (appType() == "and") {
        return (App.getUser())
    }
    if (appType() == "ios") {
        window.webkit.messageHandlers.getUser.postMessage("11")
        return await getAppMethodListener('getUser')
    }
}

/**
 * 作用：获取用户端 当前用户ID、token
 */
export async function getUserIdAndToken(baseURLApp) {
    console.log('212121')
    if (appType() === "and" || appType() === "ios") {
        let userData = await appGetUser()
        let token = await appGetToken()
        return new Promise((resolve, reject) => {
            let params = {
                userData: userData,
                token: token
            }
            requestUtil.post(baseURLApp + '/app/decryptUserData/v1', params).then((res) => {
                if (res.code == 1) {
                    resolve({ userId: res.data.id, token })
                } else {
                    reject()
                }
            }).catch(() => {
                reject()
            })
        })
    } else if (appType() === "mini") {
        let param = getParam()
        return { userId: param.userId, token: param.token }
    }
}


/**
 * 作用：获取当前用户经纬度信息
 */
export async function appGetLocal() {
    return new Promise((resolve) => {
        if (appType() == "and") {
            let _result = App.getLocal()
            resolve(_result)
        }
        if (appType() == "ios") {
            window.webkit.messageHandlers.getLocal.postMessage("11")
            setTimeout(() => { // 解决部分机型不会放回值，转态一直处于pending，无法继续
                console.log('timeout')
                resolve('lat-Failed|long-Failed')
            }, 3000)
            getAppMethodListener('getLocal').then((data) => {
                console.log('getAppMethodListener, resolve')
                resolve(data)
            }).catch((e) => {
                console.log('getAppMethodListener,e', e)
            })
        }
    })

}
/**
 * 作用当前用户场馆信息
 */
export async function appGetLocalVenue() {
    try {
        if (appType() == "and") {
            return (App.getLocalVenue())
        }
        if (appType() == "ios") {
            window.webkit.messageHandlers.getLocalVenue.postMessage("11")
            return await getAppMethodListener('getLocalVenue')
        }
    } catch (error) { }

}

/**
 * 作用：获取当前用户设备号
 */
export async function appGetDevice() {
    if (appType() == "and") {
        return (App.getDeviceInfoHash() + "_" + App.getAndroidID())
    }
    if (appType() == "ios") {
        window.webkit.messageHandlers.getDevice.postMessage("11")
        return await getAppMethodListener('getDevice')
    }
}

/**
 * 作用：获取用户token
 */
export async function appGetToken() {
    if (appType() == "and") {
        return (App.getToken())
    }
    if (appType() == "ios") {
        window.webkit.messageHandlers.getToken.postMessage("11")
        return await getAppMethodListener('getToken')
    }
}

/**
 * 作用：获取AndroidID
 */
export async function appGetAndroidID() {
    if (appType() == "and") {
        return (App.getAndroidID())
    }
}

/**
 * 作用：获取getLongMac
 */
export async function appGetLongMac() {
    if (appType() == "and") {
        return (App.getLongMac())
    }
}

/**
 * 作用：获取设备哈希值
 */
export async function appDeviceInfoHash() {
    if (appType() == "and") {
        return (App.getDeviceInfoHash())
    }
}

/**
 * 作用：获取设备详细信息
 */
export async function appGetDeviceInfo() {
    if (appType() == "and") {
        return (App.appGetDeviceInfo())
    }
}

/**
 * 作用：挂载app回调方法
 * @param {*} appMethodName app方法名字
 * @param {*} callback 回调方法名字
 */
export function getAppMethodListener(appMethodName) {
    return new Promise((resolve) => {
        try {
            window[`${appMethodName}`] = (res) => {
                // let obj = {}
                // obj[`${appMethodName}`] = res
                resolve(res)
            }
        } catch (error) {
            resolve()
        }
    })
}

/**
 * 作用：app返回
 */
export function appBack() {
    let isBackToH5 = getParam().isBackToH5

    if (isBackToH5 == 'true') {
        window.history.go(-1)
    } else {
        if (appType() == "and") {
            App.app_finish()
        }
        if (appType() == "ios") {
            window.webkit.messageHandlers.navBack.postMessage("11")
        }
    }
}

export function newAppBack() {
    console.log('window.history.length', window.history.length);
    if (window.history.length == 1) {
        closeWindow();
        return;
    }
    const starPath = sessionStorage.getItem('startPath');
    if (router.currentRoute.path === starPath) {
        if (store.state.openByApp) {
            closeWindow();
        } else {
            defaultApp();
            router.go(-1);
            // setTimeout(()=>{//hack
            //     closeWindow();
            // },100)
        }
    } else {
        router.go(-1);
    }
}

export function closeWindow() {
    defaultApp();
    if (appType() == 'mini') {
        wx.miniProgram.navigateBack();
    } else {
        window.App && App.app_finish();
        window.webkit && window.webkit.messageHandlers.navBack.postMessage("11");
    }
}

/**
 * 作用：还原设置
 */
export function defaultApp() {
    try {
        window.App && App.interceptBackPressed("0");//安卓开启后退
        if (window.webkit) {
            //ios 开启手势
            window.webkit.messageHandlers.gestureRecognizer.postMessage({
                "isOff": "1"
            })
        }
    } catch (error) {
        console.log(error);
    }
}
/**
 * 作用：初始化后退
 */
export function initBack() {
    try {
        window.App && App.interceptBackPressed("1");//安卓禁用后退
        if (window.webkit) {
            //ios 禁用手势
            window.webkit.messageHandlers.gestureRecognizer.postMessage({
                "isOff": "0"
            })
        }
        if (!window['onBackPressed']) {
            window['onBackPressed'] = (r) => {
                newAppBack();
            }
        }
    } catch (error) {
        console.log(error);
    }
}
/**
 * 作用：隐藏返回键
 */
export function hideAppBackBtn() {
    try {
        if (appType() == "and") {
            App.hiddenBackBtn()
        }
        if (appType() == "ios") {
            window.webkit.messageHandlers.hiddenBackBtn.postMessage("11")
        }
    } catch (error) {
        console.log('h5')
    }

}


/**
 * 作用：隐藏app顶部栏
 */
export function hideBar() {
    if (appType() === 'and') {
        App.hideBar()   // 2020.11.03提供
    }

}

/**
 * 作用：显示app顶部栏
 */
export function showBar() {
    if (appType() === 'and') {
        App.showBar()
    }
}
/**
 * 作用：获取手机号
 */
export async function getMobile() {
    try {
        if (appType() == "and") {
            return (App.getMobile())
        }
        if (appType() == "ios") {
            window.webkit.messageHandlers.getMobile.postMessage('1')
            return await getAppMethodListener('getMobile').then((res) => res)
        }
    } catch (err) {
        console.log(err)
    }
}
// 打电话
export async function call(number) {
    try {
        if (appType() == "and") {
            App.callPhone(number)
        } else if (appType() == "ios") {
            window.webkit.messageHandlers.callPhone.postMessage(number)
        } else {
            window.location.href = `tel:${number}`
        }

    } catch (err) {
        console.log(err)
    }
}

/**
 * 作用：获取安卓头部状态栏高度
 */
export function getBarHeight() {
    try {
        if (appType() === 'and') {
            return App.getBarHeight()
        }
    } catch (err) {
        console.log(err)
    }
}

/**
 * 作用：app的toast
 * @param {*} message 消息
 */
export function appToast(message) {
    if (appType() == "and") {
        App.showToast(message);
    }
    if (appType() == "ios") {
        window.webkit.messageHandlers.showToast.postMessage(message);
    }
}

/**
 * 作用：app调用原生分享
 * @param {*} imgUrl 分享图片
 * @param {*} title 标题
 * @param {*} shareUrl 分享地址
 * @param {*} desc 分享简介
 */
export function appShare(imgUrl, title, shareUrl, desc) {
    if (appType() == "and") {
        App.onShare1(imgUrl, title, shareUrl, desc);
    } else if (appType() == "ios") {
        window.webkit.messageHandlers.customShare.postMessage(JSON.stringify({
            "image": imgUrl,
            "title": title,
            "url": shareUrl,
            "content": desc
        }));
    }
}

/**
 * 作用：app自定义分享
 * @param {*} type 分享类型 1，网页分享，2图片分享,3小程序分享 类型String
 * @param {*} params title 标题 shareUrl 分享地址 desc 分享简介
 */
export function webAppShare(type = 1, params = {}, callback = function () { }) {
    let wxParams = {
        type,
        params
    }, state = true;
    try {
        if (appType() == "and") {
            wxParams.params.image = wxParams.params.image.replace('data:image/png;base64,', '')
            App.onShareWeCharAndWeZoom(JSON.stringify(wxParams))
        } else if (appType() == "ios") {
            window.webkit.messageHandlers.wechatShare.postMessage(wxParams);
        }
    } catch (error) {
        state = false
        console.log('微信分享错误处理')
        console.log(error);
    }
    callback(state)
}

/**
 * @param {*} imgUrl 图片地址
 */
export async function saveCreateImg(imgUrl) {
    try {
        if (appType() == "and") {
            return (App.onShareSaveImage(imgUrl.replace('data:image/png;base64,', '')))
        }
        if (appType() == "ios") {
            window.webkit.messageHandlers.saveImage.postMessage(imgUrl);
            return await getAppMethodListener('saveImage')
        }
    } catch (error) {
        console.log('下载错误')
    }

}

/**
 * 作用：页面跳转
 * @param {*} names
 * @param {*} val
 */
export function ruinapp(names, val) {
    if (appType() == "and") {
        //跳转app课程或者训练页面 cruin(type,value)，
        //通用跳转app充值页面 web2native(1,""),跳课包web2native("2","")
        //调用app分享App.onShare()
        App.cruin(names, val);
    }
    if (appType() == "ios") {
        //通用跳转app充值页面 reCharge，跳转训练页面 train，跳转课程页面 course ，调用app分享 agShare；postMessage值必填，
        //通用跳转跳课包页面 PTPackage
        if (names == "train") {
            window.webkit.messageHandlers.train.postMessage(val);
        } else if (names == "course") {
            window.webkit.messageHandlers.course.postMessage(val);
        }
    }
}

/**
 * 作用：根据type跳转不同app页面
 * @param {*} type 1:私教  3：团课
 */
export function gotoAppPage(type, value = {}) {
    const openType = appType();
    switch (type) {
        case 1: {
            // '私教'
            if (openType == "and") {
                App.web2native("4", "")
            } else if (openType == "ios") {
                window.webkit.messageHandlers.PTExclusive.postMessage("11")
                // window.webkit.messageHandlers.pushCourseTab.postMessage({
                //     "type": "1"
                // })
            } else {
                let hasP = typeof (value) != 'object'
                wx.miniProgram.navigateTo({
                    // url: '/pages/priCoach/index?venueId=' + value
                    url: `/pages/priCoach/index${hasP ? '?venueId=' + value : ''}`
                })
            }
            break;
        }
        case 2: {
            // '充值'
            if (openType == "and") {
                App.web2native(1, '')
            } else if (openType == "ios") {
                window.webkit.messageHandlers.reCharge.postMessage('11');
            } else {
                wx.miniProgram.navigateTo({
                    url: '/pages/subPages/balance/index'
                })
            }
            break;
        }
        case 3: {
            // '团课'
            if (openType == "and") {
                App.web2native("3", "")
            } else if (openType == "ios") {
                window.webkit.messageHandlers.courseExclusive.postMessage("11")
                // window.webkit.messageHandlers.pushCourseTab.postMessage({
                //     "type": "0"
                // })
            } else {
                wx.miniProgram.navigateTo({
                    url: '/pages/courseList/courseList'
                })
            }
            break;
        }
        case 4: {
            //礼品卡跳转兑换新卡页
            if (openType == "and") {
                App.web2native(27, "")
            } else if (openType == "ios") {
                window.webkit.messageHandlers.pushGiftCard.postMessage({
                    type: 27
                })
            } else {
                wx.miniProgram.navigateTo({
                    url: '/pages/subPages/exchange/index'
                })
            }
            break;
        }
        case 5: {
            //礼品卡跳转购买礼品卡页
            if (openType == "and") {
                App.web2native(20, "")
            } else if (openType == "ios") {
                window.webkit.messageHandlers.pushGiftCard.postMessage({
                    type: 20
                })
            } else {
                wx.miniProgram.navigateTo({
                    url: '/pages/subPages/giftCard/index'
                })
            }
            break;
        }
        case 6: {
            //礼品卡详情跳转转赠礼品卡页
            if (!value.cardId) {
                Toast('需要cardId哦');
                return;
            }
            if (openType == "and") {
                App.web2native(28, value.cardId)
            } else if (openType == "ios") {
                window.webkit.messageHandlers.pushGiftCard.postMessage({
                    type: 28,
                    ...value
                })
            } else {
                wx.miniProgram.navigateTo({
                    url: '/pages/subPages/giftCardPresent/index?cardId=' + value.cardId
                })
            }
            break;
        }
        case 7: {
            //礼品卡详情跳转订单页
            if (!value.payOrderNo) {
                Toast('需要payOrderNo哦');
                return;
            }
            if (openType == "and") {
                App.web2native(29, value.payOrderNo)
            } else if (openType == "ios") {
                window.webkit.messageHandlers.pushGiftCard.postMessage({
                    type: 29,
                    ...value
                })
            } else {
                wx.miniProgram.navigateTo({
                    url: '/pages/orderDetail/orderDetail?payOrderNo=' + value.payOrderNo
                })
            }
            break;
        }
        case 8: {
            //跳转小班课主页
            /**
             * pushHomeXBKVC 小班课专属页
             * pushXBKAllClassVC 小班课-每日排课
             * pushXBKALLLessonVC  小班课-班级页
             *
             * 3000  小班课
             * 3001|约课页|小班课-班级页
             * 3002|约课页|小班课-排课页
             */
            if (openType == "and") {
                App.web2native(3000, "")
            } else if (openType == "ios") {
                window.webkit.messageHandlers.pushHomeXBKVC.postMessage({})
            } else {
                wx.miniProgram.navigateTo({
                    url: '/pages/miniCamp/index'
                })
            }
            break;
        }
        case 9: {
            // 小班课找课程页
            if (openType == "and") {
                App.web2native("1005", value)
            } else if (openType == "ios") {
                window.webkit.messageHandlers.pushCourseTab.postMessage({
                    "type": "2"
                })
            } else {
                wx.miniProgram.navigateTo({
                    url: `/pages/miniCamp/index`
                })
            }
            break;
        }
        case 10: {
            // 小班课-班型详情 value：班型ID（string）
            /**
             * andro
             * 3003      班型详情
             * 3004      班级详情
             * 3005      排课详情
             *
             * ios
             * pushXBKSchemeVC  {schemeId:xx} 班型详情
             * pushXBKClassVC {classId:xx}   班级详情
             * pushXBKLessonVC {lessonId:xx}  排课详情
             */
            if (openType == "and") {
                App.web2native("3003", JSON.stringify({ schemeId: value }))
            } else if (openType == "ios") {
                window.webkit.messageHandlers.CustomViewController.postMessage({
                    "className": "SDXBKIntroduceCommentViewController", "params": {
                        schemeId: value
                    }
                })
            } else {
                wx.miniProgram.navigateTo({
                    url: `/pages/miniCampPages/miniCampDetail/index?scene=${value}`
                })
            }
            break;
        }
        case 11: {
            // 小班课-班级详情 value：班级ID（string）
            if (openType == "and") {
                App.web2native("3004", JSON.stringify({ classId: value }))
            } else if (openType == "ios") {
                window.webkit.messageHandlers.pushXBKClassVC.postMessage({
                    classId: value
                })
            } else {
                wx.miniProgram.navigateTo({
                    url: `/pages/miniCampPages/classDetail/index?classId=${value}`
                })
            }
            break;
        }
        // 班级列表页面
        case 12: {
            try {
                if (openType === "and") {
                    App.intentToActivity(JSON.stringify({
                        "className": ".view.xiaobanke.activity.XbkCourseDetailActivity", "data": [
                            {
                                "key": "EXTRA_SCHEMEID",
                                "value": value
                            }]
                    })
                    )
                } else if (openType === "ios") {
                    window.webkit.messageHandlers.CustomViewController.postMessage({
                        "className": "SDXBKCourseDetailViewController", "params": {
                            schemeId: value
                        }
                    })
                } else if (openType === "mini") {
                    wx.miniProgram.navigateTo({
                        url: '/pages/miniCampPages/miniCampDetailCourse/index?schemeId=' + value
                    })
                }
            } catch (e) {
                console.log(e)
            }
            break;
        }
        // 私教全部课程页
        case 13: {
            try {
                if (openType === "and") {
                    App.intentToActivity(JSON.stringify({
                        "className": ".ui.privatecoach.privatrain2.PerTraProjectListActivity",
                        "data": []
                    }))
                } else if (openType === "ios") {
                    window.webkit.messageHandlers.CustomViewController.postMessage({
                        "className": "SDPTCourseListViewController"
                    })
                } else if (openType === "mini") {
                    wx.miniProgram.navigateTo({
                        url: '/pages/priPages/courseTypeList/index'
                    })
                }
            } catch (e) {
                console.log(e)
            }
            break;
        }
        // 小班课-排课页
        case 14: {
            if (openType == "and") {
                App.web2native(3002, "")
            } else if (openType == "ios") {
                window.webkit.messageHandlers.pushXBKAllClassVC.postMessage({})
            } else {
                wx.miniProgram.navigateTo({
                    url: '/pages/miniCampPages/allSchdule/index'
                })
            }
            break;
        }
        // 快速约课
        case 15: {
            if (openType == "and") {
                App.web2native(3007, "")
            } else if (openType == "ios") {
                window.webkit.messageHandlers.CustomViewController.postMessage({
                    "className": "SDQuickAppointViewController"
                })
            } else {
                wx.miniProgram.navigateTo({
                    url: '/pages/shortcut/index'
                })
            }
            break;
        }
        // 意见反馈
        case 16: {
            if (openType == "and") {
                App.web2native(3008, "")
            } else if (openType == "ios") {
                window.webkit.messageHandlers.CustomViewController.postMessage({
                    "className": "SDFeedBackViewController"
                })
            } else {
                wx.miniProgram.navigateTo({
                    url: '/pages/uploadPages/feedback/feedback'
                })
            }
            break;
        }
        // 越练越便宜
        case 17: {
            if (openType == "and") {
                App.web2native(26, "")
            } else if (openType == "ios") {
                window.webkit.messageHandlers.CustomViewController.postMessage({
                    "className": "SDTicketDiscountViewController"
                })
            } else {
                wx.miniProgram.navigateTo({
                    url: '/pages/activity/discount/index'
                })
            }
            break;
        }
        // 找课程-团课
        case 18: {
            if (openType === "and") {
                App.web2native("1001", "")
            } else if (openType === "ios") {
                window.webkit.messageHandlers.pushCourseTab.postMessage({
                    "type": "0"
                })
            } else {
                wx.miniProgram.navigateTo({
                    url: '/pages/courseList/courseList'
                })
            }
            break;
        }
    }
}
/**
 * 作用：跳转门店功能页
 * @param {Number} type 跳转类型  1 门店列表 2 门店详情
 * @param {Number|String} venueId 门店ID  type为2时必传
 */
export function gotoVenue(type, venueId) {
    switch (type) {
        case 1:
            if (appType() == "and") {
                App.web2native("14", {})
            } else if (appType() == "ios") {
                window.webkit.messageHandlers.CustomViewController.postMessage({ "className": "SDStoreListViewController" })
            } else if (appType() == "mini") {
                wx.miniProgram.navigateTo({
                    url: '/pages/venue/list/index'
                })
            }
            break;
        case 2:
            if (appType() == "and") {
                App.intentToActivity(JSON.stringify({
                    "className": ".mvp.view.activity.venue.VenueDetailActivity", "data": [
                        {
                            "key": "extra_venue_id",
                            "value": venueId
                        }]
                })
                )
            } else if (appType() == "ios") {
                window.webkit.messageHandlers.CustomViewController.postMessage({ "className": "SDStorePageViewController", venueId: venueId })
            } else if (appType() == "mini") {
                wx.miniProgram.navigateTo({
                    url: '/pages/venue/detail/index?venueId=' + venueId
                })
            }
            break;
    }
}

/**
 * 作用：前往课程详情页面
 * @param {*} courseId 课程id
 */
export function gotoCourseDetail(courseId) {
    try {
        if (appType() == "and") {
            App.cruin("courseDetail", courseId);
            //这个是安卓操作系统
        } else if (appType() == "ios") {
            window.webkit.messageHandlers.courseDetail.postMessage(courseId);
            //这个是ios操作系统
        } else {
            wx.miniProgram.navigateTo({
                url: '/pages/leagueLecture/courseDetail/couseDetail?mouldId=' + courseId
            })
            // 小程序跳转
        }
    } catch (error) {
        console.log('h5-跳转课程详情')
    }

}
/**
 * 作用：前往教练页面
 * @param {*} teacherId 教练id
 */
export function gotoCoachDetail(teacherId) {
    try {
        if (appType() == "ios") {
            window.webkit.messageHandlers.coachPage.postMessage({ teacherId: teacherId });
        } else if (appType() == 'and') {
            App.web2native(8, teacherId)
        } else {
            wx.miniProgram.navigateTo({
                url: `/pages/priPages/coachDetail/coachDetail?teacherId=${teacherId}`
            })
        }
    } catch (e) {
        console.log(e)
    }

}
/**
 * 作用：前往xx页面
 *  @param {*} type 51教练主页
 * @param {*} teacherId 教练id
 * @param {*} showPopView 是否弹窗
 */
export function universalPushVC(type, teacherId, showAllCoachCoursePop) {
    try {
        if (appType() == "ios") {
            window.webkit.messageHandlers.universalPushVC.postMessage({ type, teacherId, showAllCoachCoursePop });
        } else if (appType() == 'and') {
            App.web2native(type, teacherId)
        } else {
            wx.miniProgram.navigateTo({
                url: `/pages/priPages/coachDetail/coachDetail?teacherId=${teacherId}${showAllCoachCoursePop ? '&autoOpenGroup=1' : ''}`,
            })
        }
    } catch (e) {
        console.log(e)
    }

}
/**
 * 作用：前往订单列表页面
 */
export function gotoOrderList() {
    try {
        if (appType() === "and") {
            App.web2native("9", "")
        } else if (appType() === "ios") {
            window.webkit.messageHandlers.SDMyOrderListViewController.postMessage({})
        } else {
            wx.miniProgram.navigateTo({
                url: `/pages/orderList/orderList`
            })
        }
    } catch (e) {
        console.log(e)
    }

}

/**
 * 作用：跳转客服页面
 * @param path 标识
 */
export function gotoCustomerService(path) {
    if (appType() == "and") {
        App.web2CustomerService(0, path)
    }
    if (appType() == "ios") {
        window.webkit.messageHandlers.mqkf.postMessage({ path: path })
    }
    if (appType() == "mini") {
        wx.miniProgram.navigateTo({
            url: '/pages/qiyusdk/qiyu/index'
        })
    }
}
/**
 * 作用：原生页面销毁切换到h5页面的生命周期
 */
export function viewDidAppear() {
    if (appType() == "and") {
        // 安卓
        window['onCreate'] = () => {
            console.log('安卓页面调用')
        }
    }
    if (appType() == "ios") {
        // ios
        window['viewDidAppear'] = () => {
            console.log('ios调用')
        }
    }
}

/**
 * 作用：APP退到后台时候调用方法
 */
export function enterBackground(callbak = function () { }) {
    try {
        if (appType() == "and") {
            // 安卓
            window['enterBackground'] = () => {
                callbak('and')
            }
        }
        if (appType() == "ios") {
            // ios
            window['EnterBackground'] = () => {
                callbak('ios')
            }
        }
    } catch (err) {
        console.log(err)
    }
}

/**
 * 作用：APP退到前台时候调用方法
 */
export function enterForeground(callbak = function () { }) {
    try {
        if (appType() == "and") {
            // 安卓
            window['enterForeground'] = () => {
                callbak('and')
            }
        }
        if (appType() == "ios") {
            // ios
            window['EnterForeground'] = () => {
                callbak('ios')
            }
        }
    } catch (err) {
        console.log(err)
    }
}
export function toLoginPage() {
    try {
        if (appType() == "and") {
            App.intentToActivity(JSON.stringify({
                "className": ".ui.me.LoginActivity",
                "data": []
            }))
        }
        if (appType() == "ios") {
            // ios
            window.webkit.messageHandlers.pushLogin.postMessage("11")
        }
        if (appType() == "mini") {
            let param = '?webUrl=' + window.location.href.split('?')[0], options = getParam()
            options.userId = '1'
            options.token = '1'
            for (let key in options) {
                param += `&${key}=${options[key]}`
            }
            let linkVal = encodeURIComponent('/pages/webView/index' + param)
            wx.miniProgram.reLaunch({
                url: "/pages/login/login?isJump=1&linkVal=" + linkVal
            })
        }
    } catch (err) {
        console.log(err)
    }
}
// 跳转首页
export function toHomePage() {
    try {
        if (appType() == "and") {
            App.intentToActivity(JSON.stringify({
                "className": ".MainActivity",
                "data": []
            }))
        }
        if (appType() == "ios") {
            window.webkit.messageHandlers.navBackRoot.postMessage("11")
        }
        if (appType() == "mini") {
            wx.miniProgram.switchTab({
                url: '/pages/home/index'
            })
        }
    } catch (err) {
        console.log(err)
    }
}
/**
 * 作用：登录过期
 */
export function loginOut() {
    try {
        if (appType() == "and") {
            // 安卓
            App.loginOut()
        }
        if (appType() == "ios") {
            // ios
            window.webkit.messageHandlers.loginOut.postMessage("11")
        }
        if (appType() == "mini") {
            let param = '?webUrl=' + window.location.href.split('?')[0], options = getParam()
            options.userId = '1'
            options.token = '1'
            for (let key in options) {
                param += `&${key}=${options[key]}`
            }
            let linkVal = encodeURIComponent('/pages/webView/index' + param)
            wx.miniProgram.reLaunch({
                url: "/pages/login/login?isJump=1&linkVal=" + linkVal
            })
        }
    } catch (err) {
        console.log(err)
    }
}

/**
 * app周期支付
 * @param payParam 传equityCardId 和equityCardItemId 的json串
 */
export function appAutoRenewPay(payParam = {}) {
    let p = JSON.stringify(payParam)
    try {
        if (appType() === 'mini') {
            Toast('小程序暂不支持自动续费，请移步APP体验！')
        }
        if (appType() === 'ios') {
            window.webkit.messageHandlers.payCycleWebToNative.postMessage(p);
        }
        if (appType() === 'and') {
            App.payCycleWebToNative(p);
        }
    } catch (e) {
        console.log(e)
    }
}

/**
 * 作用：app、小程序内不弹窗直接支付
 * @param {String | Number} payType 1：微信，2：支付宝，3：云闪付
 * @param {Object} payParam 同下
 * @param {String} url 小程序支付完成后的回跳地址
 */
export function silentAppPay(payType, payParam, url) {
    try {
        let p = JSON.stringify(payParam)
        if (appType() === 'mini') {
            payParam.wxPayType = 'applet'
            let redirectUri = encodeURIComponent(url)
            wx.miniProgram.redirectTo({
                url: `/pages/webViewPay/index?redirect_uri=${redirectUri}&payData=${p}&payType=${payType}`,
                success: function (re) {
                    console.log('跳转成功');
                },
                fail: function (r) {
                    console.log('跳转失败');
                }
            })
        }
        payType += ''
        if (appType() === 'ios') {
            window.webkit.messageHandlers.webSelfPay.postMessage({ params: payParam, paymentType: payType });
        }
        if (appType() === 'and') {
            App.payment(payType, p);
        }
    } catch (e) {
        console.log('错误')
    }
}

/**
 * 作用：app、小程序内支付
 * @param {*} courseId 课程id
 * @param {*} token
 * @param {*} userId 用户id
 * @param {*} venueId 场馆id  '31'线上商店
 * @param {*} subject 支付时弹出窗的右标题
 * @param {*} originalPriceStr 不知道什么意思，一般都是空字符
 * @param {*} totalAmount 金额
 * @param {*} mouldId 商品id
 * @param {*} type 购买类型，跟服务端确认
 * @param {*} title 支付时弹出窗的左标题
 * @param {*} extendsType 活动类型。只有充值活动才用拓展
 * @param {*} wxPayType 'applet'  小程序独有
 */
export function appPay(payParam = {}, url) {
    // url为小程序支付完成后的回跳地址
    // (例：/pages/webView/index?webUrl=appWeb/activePage/anniversary2021-recharge.html&userId=1&token=1&location=1)
    // 注意：若链接后带参数 不加？ 直接&拼接
    try {

        /** 暂时修改小程序内支付方法
        if (appType() === 'mini') {
          payParam.wxPayType = 'applet'
          let p = JSON.stringify(payParam)
          let redirectUri = encodeURIComponent(url)
          console.log(redirectUri);
          wx.miniProgram.redirectTo({
            url: '/pages/webViewPay/index?redirect_uri='+ redirectUri +'&payData='+ p,
            success: function(re) {
              console.log('跳转成功');
            },
            fail: function(r) {
              console.log('跳转失败');
            }
          })
        }
        */
        if (appType() === 'mini') {
            store.commit('setShowPayPopup', true)
            store.commit('setPayData', payParam)
            store.commit('setPayUrl', url)
        }



        let p = JSON.stringify(payParam)
        if (appType() === 'ios') {
            window.webkit.messageHandlers.webpay.postMessage(p);
        }
        if (appType() === 'and') {
            App.payWebToNative(p);
        }
    } catch (e) {
        console.log('错误')
    }
}
/**
 * 作用：支付成功 监听
 */
export function appPaySuccess() {
    return new Promise((resolve, reject) => {
        if (appType() === 'mini') {
            let params = getParam()
            if (params.msg && params.code == 1) {
                Toast('支付成功')
                resolve()
            } else if (params.code == 0) {
                setTimeout(() => {
                    Toast({
                        message: ((params.msg && decodeURIComponent(params.msg)) || '支付失败'),
                        duration: 3000
                    })
                }, 200)

                reject()
            }
        }
        window['paySuccess'] = (r) => {
            if (r === '1') {
                // Toast('支付成功')
                resolve()
            } else {
                reject()
            }
        }
        window['appUpdate'] = (r) => {
            if (r === '1') {
                // Toast('支付成功')
                resolve()
            } else {
                reject()
            }
        }
    })
}


/**
 * 作用：打开用户内置地图
 */
export function navMap(params) {
    try {
        let data = JSON.stringify(params)
        if (appType() == "and") {
            // 安卓
            App.navMap(data)
        }
        if (appType() == "ios") {
            // ios
            window.webkit.messageHandlers.navMap.postMessage(data)
        }
        if (appType() === 'mini') {
            wx.miniProgram.navigateTo({
                url: '/pages/webViewMap/index?jsonData=' + data,
                success: function (re) {
                    console.log('跳转成功');
                },
                fail: function (r) {
                    console.log('跳转失败');
                }
            })
        }
    } catch (err) {
        console.log(err)
    }
}



/**
 * 教练端使用
 * 作用：获取用户Id
 */
export async function appGetUserId() {

    try {
        if (appType() == "and") {
            return (App.getUserId())
        }
        if (appType() == "ios") {
            window.webkit.messageHandlers.userId.postMessage("11")
            return await getAppMethodListener('userId')
        }
    } catch (err) {
        console.log(err)
    }
}

/**
 * 教练端使用
 * 作用：获取教练Id
 */
export async function appGetTeacherId() {
    try {
        if (appType() == "and") {
            return (App.getTeacherId())
        }
        if (appType() == "ios") {
            window.webkit.messageHandlers.teacherId.postMessage("11")
            return await getAppMethodListener('teacherId')
        }
    } catch (err) {
        console.log(err)
    }
}
/**
 * pad端使用
 * 作用：获取教练Id
 */
export async function padGetTeacherId() {
    try {
        if (appType() == "ios") {
            window.webkit.messageHandlers.getTeacherId.postMessage("11")
            return await getAppMethodListener('getTeacherId')
        }
    } catch (err) {
        console.log(err)
    }
}
/**
 * 教练端使用
 * 作用：获取token
 */
export async function appGetTeacherToken() {
    try {
        if (appType() == "and") {
            return (App.getToken())
        }
        if (appType() == "ios") {
            window.webkit.messageHandlers.token.postMessage("11")
            return await getAppMethodListener('token')
        }
    } catch (err) {
        console.log(err)
    }
}

/**
 * 作用：跳转企业团课详情页面
 * @param {String} mouldId 课程id
 */
export function companyCourseDetail(mouldId) {
    try {
        if (appType() == "and") {
            App.web2native('30', mouldId)
        }
        if (appType() == "ios") {
            window.webkit.messageHandlers.companyCourseDetail.postMessage({ mouldId });
        }
    } catch (err) {
        console.log(err)
    }
}

/**
 * 作用：当前APP版本号
 */
export async function getAppVersion() {
    try {
        if (appType() == "and") {
            return (App.getVersionName())
        }
        if (appType() == "ios") {
            window.webkit.messageHandlers.bundleId.postMessage("11")
            return await getAppMethodListener('bundleId')
        }
    } catch (err) {
        console.log(err)
    }
}

/**
 * 作用：打开 我的优惠券
 */
export function goMyVoucher(value = '') {
    try {
        if (appType() == "and") {
            App.intentToActivity(JSON.stringify({
                "className": ".ui.coupons.MyCouponsForCommonActivity",
                "data": []
            }))
        }
        if (appType() == "ios") {
            window.webkit.messageHandlers.CustomViewController.postMessage({
                className: 'SDCouponViewController',
                "params": {
                    couponType: value
                }
            })
        }
        if (appType() === 'mini') {
            wx.miniProgram.navigateTo({
                url: '/pages/subPages/myCoupons/myCoupons',
                success: function (re) {
                    console.log('跳转成功');
                },
                fail: function (r) {
                    console.log('跳转失败');
                }
            })
        }
    } catch (err) {
        console.log(err)
    }
}
/**
 * 作用：打开 我的课包
 */
export function goMyClassBag() {
    try {
        if (appType() == "and") {
            App.intentToActivity(JSON.stringify({
                "className": ".ui.coupons.MyCouponsForPackageActivity",
                "data": []
            }
            ))
        }
        if (appType() == "ios") {
            window.webkit.messageHandlers.CustomViewController.postMessage({
                className: 'SDMyPackageCouponViewController'
            })
        }
        if (appType() === 'mini') {
            wx.miniProgram.navigateTo({
                url: '/pages/subPages/myCoursePackage/index',
                success: function (re) {
                    console.log('跳转成功');
                },
                fail: function (r) {
                    console.log('跳转失败');
                }
            })
        }
    } catch (err) {
        console.log(err)
    }
}
/**
 * 作用：打开 余额明细
 */
export function goMyMoney() {
    try {
        if (appType() == "and") {
            App.web2native("11", "")
        }
        if (appType() == "ios") {
            window.webkit.messageHandlers.SDUserCountViewController.postMessage({
                consumptionType: '1'
            })
        }
        if (appType() === 'mini') {
            wx.miniProgram.navigateTo({
                url: '/pages/subPages/myBalance/index',
                success: function (re) {
                    console.log('跳转成功');
                },
                fail: function (r) {
                    console.log('跳转失败');
                }
            })
        }
    } catch (err) {
        console.log(err)
    }
}

/**
 * 作用：私教课程 详情页 有视频那个
 */
export function goPriDetail(id) { // projectId
    try {
        if (appType() == 'mini') {
            wx.miniProgram.navigateTo({
                url: `/pages/priPages/courseTypeDetail/index?id=${id}`,
            })
        }
        if (appType() == "and") {
            App.web2native("15", id)
        }
        if (appType() == "ios") {
            window.webkit.messageHandlers.PTDetail.postMessage({
                mouldId: id
            })
        }
    } catch (err) {
        console.log(err)
    }
}
/**
 * 作用：超鹿会员页
 */
export function goVipCenter() {
    try {
        if (appType() == "and") {
            App.web2native("10", "")
        }
        if (appType() == "ios") {
            window.webkit.messageHandlers.userRights.postMessage('1')
        }
        if (appType() == "mini") {
            wx.miniProgram.navigateTo({
                url: `/pages/subPages/superDeerVip/index`,
            })
        }
    } catch (err) {
        console.log(err)
    }
}
/**
 * 作用：待上课
 */
export function goStayCourse() {
    try {
        if (appType() == "and") {
            App.web2native("32", "")
        }
        if (appType() == "ios") {
            window.webkit.messageHandlers.pushMyClassListVC.postMessage({
                defaultIndex: 0
            })
        }
    } catch (err) {
        console.log(err)
    }
}
/**
 * 作用：团课快速约课 弹窗
 */
export function showGroupQuickDialog(id) {
    try {
        if (appType() == "and") {
            App.showMakeGroupDialog(id)
        }
        if (appType() == "ios") {
            window.webkit.messageHandlers.quickCourse.postMessage({
                courseId: id
            })
        }
    } catch (err) {
        console.log(err)
    }
}
/**
 * 作用：打开 用户主页
 */
export function goUserPage(id, type) {
    try {
        if (appType() == "and") {
            App.intentToActivity(JSON.stringify({
                "className": ".mvp.view.activity.memberhomepage.MemberHomePageActivity",
                "data": [
                    {
                        "key": "extra_userid",
                        "value": id
                    }
                ]

            }
            ))
        }
        if (appType() == "ios") {
            window.webkit.messageHandlers.CustomViewController.postMessage({
                "className": 'SDUserPageViewController',
                "params": {
                    "viewUserId": id + '',
                    "type": type + '' // 1自己 2用户
                }

            })
        }
        if (appType() === 'mini') { }
    } catch (err) {
        console.log(err)
    }
}
/**
 * 作用：通用跳转原生web页   ios跳转链接中不能含有中文 需encodeURIComponent转码
 */
export function appOpenWeb(title = '', url, isShowNavBar = '0', isShowBack = '1') {
    try {
        if (appType() == 'and') {
            App.web2native(21, JSON.stringify({ title, url, showBar: isShowNavBar }))
            // 参数 web2native(1,json)    {title,url,showBar:"0",showBarTitle:"0"}
        }
        if (appType() == 'ios') {
            window.webkit.messageHandlers.pushWebVC.postMessage({ title, url, isShowNavBar, isShowBack })
            // title  url(必传) isShowBack 传0或1（1用原生返回键） isShowNavBar 传0或1（1用原生导航栏
        }
        if (appType() == 'mini') {
            url = url.replace('?', '&')
            wx.miniProgram.navigateTo({
                url: `/pages/webView/index?webUrl=${url}`,
            })
        }
    } catch (err) {
        window.location.href = url
        console.log(err)
    }
}
/**
 * 作用：教练端专用  通用跳转原生web页   ios跳转链接中不能含有中文 需encodeURIComponent转码
 */
export function coachAppOpenWeb(title = '', url, isShowNavBar = '0', isShowBack = '1') {
    try {
        if (appType() == 'and') {
            App.web2native(1, JSON.stringify({ title, url, showBar: isShowNavBar }))
            // 参数 web2native(1,json)    {title,url,showBar:"0",showBarTitle:"0"}
        }
        if (appType() == 'ios') {
            window.webkit.messageHandlers.pushWebVC.postMessage({ title, url, isShowNavBar, isShowBack })
            // title  url(必传) isShowBack 传0或1（1用原生返回键） isShowNavBar 传0或1（1用原生导航栏
        }
    } catch (err) {
        window.location.href = url
        console.log(err)
    }
}
/**
 * 作用：打开 小班课推荐教练页
 */
export function goMiniRecommendedCoach() {
    try {
        if (appType() == "and") {
            App.web2native("3006", "")
        }
        if (appType() == "ios") {
            window.webkit.messageHandlers.CustomViewController.postMessage({ "className": "SDXBKRecommendViewController" })
        }
        if (appType() === 'mini') {
            wx.miniProgram.navigateTo({
                url: '/pages/miniCampPages/coachRecommend/index'
            })
        }
    } catch (err) {
        console.log(err)
    }
}
/**
 * 作用：打开 超鹿月卡免费团课页面
 */
export function goFreeClassList() {
    try {
        if (appType() === "and") {
            App.web2native("3011", "")
        }
        if (appType() === "ios") {
            window.webkit.messageHandlers.CustomViewController.postMessage({ "className": "SDCourseMonthlyViewController" })
        }
        if (appType() === 'mini') {
            wx.miniProgram.navigateTo({
                url: '/pages/courseListMonth/index'
            })
        }
    } catch (err) {
        console.log(err)
    }
}
/**
 * 作用：升级app
 */
export function upgrade() {
    try {
        if (appType() == "and") {
            App.intentToBrowser('https://sj.qq.com/appdetail/com.chaolu.app')
        }
        if (appType() == "ios") {
            window.webkit.messageHandlers.openURL.postMessage('https://sj.qq.com/appdetail/com.chaolu.app')
        }
    } catch (err) {
        console.log(err)
    }
}
/**
 * 作用：升级app
 */
export function upload(n) {
    try {
        if (appType() == "and") {
            App.choiceUpPic(n)
        }
        if (appType() == "ios") {
            window.webkit.messageHandlers.uploadPhoto.postMessage(n)
        }
    } catch (err) {
        console.log(err)
    }
}
export async function uploadSuccess() { // 适配了  统一都返回array
    try {
        let res = await getAppMethodListener('appChoicePic')
        if (appType() == "ios") {
            return Array.isArray(res) ? res : [res]
        }
        if (appType() == "and") {
            const a = JSON.parse(res)
            return a.faqImage || []
        }
    } catch (error) {
        console.log('上传错误')
    }

}
/**
 * 作用：通用打开app页
 */
export function goAppPageByNumber(n) {
    try {
        if (appType() === "and") {
            App.web2native(n + "", "")
        }
        if (appType() === "ios") {
            window.webkit.messageHandlers.universalPushVC.postMessage({ "type": n + "" })
        }
        if (appType() === 'mini') {
            // wx.miniProgram.navigateTo({
            //     url: '/pages/courseListMonth/index'
            // })
        }
    } catch (err) {
        console.log(err)
    }
}
