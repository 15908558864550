export default [
    {
        "path": "/superdeer-activity/group-invite-202411/index",
        "name": "superdeer-activity/group-invite-202411/index",
        "meta": { "title": "邀新上团课，赢千元大礼","headerType": 5},
        component: () => import('../views/superdeer-activity/group-invite-202411/index.vue')
    },
    {
        "path": "/superdeer-activity/group-invite-202411/ranking",
        "name": "superdeer-activity/group-invite-202411/ranking",
        "meta": { "title": "教练排行榜","headerType": 5},
        component: () => import('../views/superdeer-activity/group-invite-202411/ranking.vue')
    },
    {
        "path": "/superdeer-activity/group-invite-202411/receive",
        "name": "superdeer-activity/group-invite-202411/receive",
        "meta": { "title": "奖品领取确认","headerType": 5},
        component: () => import('../views/superdeer-activity/group-invite-202411/receive.vue')
    },
    {
        "path": "/superdeer-activity/group-invite-202411/accept",
        "name": "superdeer-activity/group-invite-202411/accept",
        "meta": { "title": "邀请有礼","headerType": 4},
        component: () => import('../views/superdeer-activity/group-invite-202411/accept.vue')
    },

    {
        "path": "/coach-own-member-coupon",
        "name": "coach-own-member-coupon",
        "meta": { "title": "领取教练专属优惠券", "headerType": 5 },
        component: () => import('../views/coach-own-member/coupon')
    },
    {
        "path": "/invite-gift",
        "name": "invite-gift",
        "meta": { "title": "邀请有礼", "headerType": 5 },
        component: () => import('../views/invite-gift/index.vue')
    },
    {
        "path": "/superdeer-activity/private-invite-202307/index",
        "name": "superdeer-activity/private-invite-202307/index",
        "meta": { "title": "邀新上私教，赢限定礼", "headerType": 5 },
        component: () => import('../views/superdeer-activity/private-invite-202307/index.vue')
    },
    {
        "path": "/superdeer-activity/private-invite-202307/receive",
        "name": "superdeer-activity/private-invite-202307/receive",
        "meta": { "title": "奖品领取确认", "headerType": 5 },
        component: () => import('../views/superdeer-activity/private-invite-202307/receive.vue')
    },
    {
        "path": "/superdeer-activity/private-invite-202307/accept",
        "name": "superdeer-activity/private-invite-202307/accept",
        "meta": { "title": "邀请有礼", "headerType": 5 },
        component: () => import('../views/superdeer-activity/private-invite-202307/accept.vue')
    },
    {
        "path": "/superdeer-activity/coupon/index/:id",
        "name": "superdeer-activity/coupon/index",
        "meta": { "title": "领券", "headerType": 5 },
        component: () => import('../views/superdeer-activity/coupon/index.vue')
    },
    {//通用，需传入3参数
        "path": "/superdeer-activity/receive-reward/index",
        "name": "superdeer-activity/receive-reward/index",
        "meta": { "title": "领奖", "headerType": 5 },
        component: () => import('../views/superdeer-activity/receiveReward/index.vue')
    },
    {
        "path": "/coach-will-survey",
        "name": "coach-will-survey",
        "meta": { "title": "我要做教练", "headerType": 5 },
        component: () => import('../views/coach-will-survey/index.vue')
    },
    {
        path: '/coach-feedback/index',
        name: 'coach/feedback/index',
        meta: { title: '教练意见反馈', "headerType": 5 },
        component: () => import('@/views/coachFeedback/index.vue')
    },
    {
        path: '/coach-feedback/record',
        name: 'coach/feedback/record',
        meta: {title: '教练反馈记录',"headerType": 5},
        component: () => import('../views/coachFeedback/record.vue')
    },
    {
        path: '/coach-feedback/detail',
        name: 'coach/feedback/detail',
        meta: {title: '教练反馈详情',"headerType": 5},
        component: () => import('../views/coachFeedback/detail.vue')
    },
    {   
        path: '/superdeer-activity/double11-2024/main', 
        name: 'superdeer-activity/double11-2024/main', 
        meta: { title: '超鹿双11', headerType: 4 }, 
        component: () => import(/* webpackChunkName: "sell" */ '../views/superdeer-activity/double11-2024/main/index') 
    },
    {   
        path: '/superdeer-activity/double11-2024/new-people', 
        name: 'superdeer-activity/double11-2024/new-people', 
        meta: { title: '新人专区', headerType: 4 }, 
        component: () => import(/* webpackChunkName: "sell" */ '../views/superdeer-activity/double11-2024/main/new-p.vue') 
    },
    {
        "path": "/superdeer-activity/double11-2024/main/send",
        "name": "activity-marketing/double11-2024/send",
        "meta": { "title": "双十一优惠券", "headerType": 4 },
        component: () => import('../views/superdeer-activity/double11-2024/main/send.vue')
    }
]
