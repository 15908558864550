export default [
  {
    "path": "/superdeer-activity/teamWarband-202308",
    "name": "teamWarband-202308",
    "meta": { "title": "团课战队", "headerType": 2 },
    component: () => import('../views/superdeer-activity/teamWarband-202308/index')
  },
  {
    "path": "/superdeer-activity/teamWarband-202308/list",
    "name": "teamWarband-202308-list",
    "meta": { "title": "团课课量明细", "headerType": 2 },
    component: () => import('../views/superdeer-activity/teamWarband-202308/list')
  },
  {
    "path": "/superdeer-activity/teamWarband-202308/list",
    "name": "teamWarband-202308-list",
    "meta": { "title": "团课课量明细", "headerType": 2 },
    component: () => import('../views/superdeer-activity/teamWarband-202308/list')
  },
  {
    "path": "/superdeer-activity/teamWarband-202308/myRecord",
    "name": "teamWarband-202308-list",
    "meta": { "title": "我的组队记录", "headerType": 2 },
    component: () => import('../views/superdeer-activity/teamWarband-202308/myRecord')
  },
  {
    "path": "/joinCompany/index",
    "name": "join-company",
    "meta": { "title": "超鹿运动", "headerType": 2 },
    component: () => import('../views/joinCompany/index')
  },
  {
    "path": "/superdeer-activity/punching-202311/group",
    "name": "superdeer-activity/punching-202311/group",
    "meta": { "title": "冬季不惧寒,锻炼不停息", "headerType": 5 },
    component: () => import('../views/superdeer-activity/punching-202311/group.vue')
  },
  {
    "path": "/superdeer-activity/punching-202311/group-gz-2312",
    "name": "superdeer-activity/punching-202311/group-gz-2312",
    "meta": { "title": "迎新打卡，腾飞2024", "headerType": 5 },
    component: () => import('../views/superdeer-activity/punching-202311/group-gz-2312.vue')
  },
  {
    "path": "/superdeer-activity/flag-202404",
    "name": "/superdeer-activity/flag-202404",
    "meta": { "title": "上团课，赢666元现金", "headerType": 5 },
    component: () => import('../views/superdeer-activity/flag-202404')
  },
  {
    "path": "/superdeer-activity/flag-202404/record",
    "name": "/superdeer-activity/flag-202404/record",
    "meta": { "title": "团课记录", "headerType": 5 },
    component: () => import('../views/superdeer-activity/flag-202404/record.vue')
  },
  {
    "path": "/superdeer-activity/flag-202404/getGift",
    "name": "/superdeer-activity/flag-202404/getGift",
    "meta": { "title": "奖品领取确认", "headerType": 5 },
    component: () => import('../views/superdeer-activity/flag-202404/getGift.vue')
  },
]
